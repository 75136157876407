.search {
    display: flex;
    align-items: center;
    justify-content: center
    
}

.inputSearch:focus-visible{
    outline: 1.5px solid #173e26;
}

.btn:hover {
    transform: scale(1.02);
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
