.scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #5a5d52;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
    z-index: 1000;
  }
  
  .scroll-button:hover {
    background-color: #2a302d;
    animation-play-state: paused; /* Pause the bouncing animation on hover */
  }
  
  .arrow-up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid white;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .bounce {
    animation: bounce 4s infinite;
  }
  