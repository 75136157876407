/* ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
} */

.list {
    margin: 2px;
}

.list a {
    text-decoration: none;
    color: white;
    position: relative;
}

.list a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
}

.list a:hover::after {
    width: 100%;
}

.nav ul ul {
    display: none;
}

.nav ul ul li {
    margin-left: 10px;
    line-height: 42px;
    border-bottom: none;
    padding: 1rem;
    border-bottom-width: 1px;
    border-color: rgb(75, 85, 99);
  }

.nav ul li.active .caret {
    transform: translateY(-50%) rotate(-180deg);
  }
.nav ul li:active > ul {
    display: block;
  }
.nav li.active ul {
    display: block;
}

.nav ul li.active ul {
    display: block;
}

@media (max-width:550px) {
    .AIDH {
        display: none;
    }
    .IDH {
        display: block;
    }
}