/* imageAccordion.css */
/* imageAccordion.css */

.bodyy {
    display: grid;
    place-items: center;
    margin: 10px 0;
  }
  
  .containerr {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
  }
  
  .single {
    padding: 0 1rem;
    display: grid;
    place-content: center;
    width: 200px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    transition: 500ms ease-in-out width;
  }
  
  .single::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(0, 0, 0, 0.3);
    transition: 500ms ease-in-out background-color;
  }
  
  .single:hover,
  .single.active {
    width: 500px;
  }
  
  .single:hover::before,
  .single.active::before {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .single .content {
    transform: rotate(-90deg);
    transition: 500ms ease-in-out transform;
    text-align: center;
  }
  
  .single:hover .content,
  .single.active .content {
    transform: rotate(0);
  }
  
  .single:hover h2,
  .single.active h2 {
    transform: scale(1);
  }
  
  .single:hover p,
  .single.active p {
    height: fit-content;
    margin-bottom: 10px;
  }
  
  .single:hover button,
  .single.active button {
    display: block;
    transform: rotate(0);
  }
  
  /* Responsive styles */
  @media screen and (max-width: 1200px) {
    .containerr {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
    }
  
    .single {
      width: 45%;
      height: 200px;
    }
  
    .single:hover,
    .single.active {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .containerr {
      flex-direction: column;
      align-items: center;
    }
  
    .single {
      width: 90%;
      height: 200px;
    }
  
    .single:hover,
    .single.active {
      width: 100%;
      height: 300px;
    }
  
    .content h2 {
      font-size: 1rem;
    }
  
    .content p {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .single {
      width: 100%;
      height: 150px;
    }
  
    .single:hover,
    .single.active {
      height: 250px;
    }
  
    .content h2 {
      font-size: 0.8rem;
    }
  
    .content p {
      font-size: 0.7rem;
    }
  }
  

.bodyy {
    display: grid;
    place-items: center;
    margin: 10px 0;
  }
  
  .containerr {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
  }
  
  .single {
    padding: 0 1rem;
    display: grid;
    place-content: center;
    width: 200px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    transition: 500ms ease-in-out width;
  }
  
  .single::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(0, 0, 0, 0.3);
    transition: 500ms ease-in-out background-color;
  }
  
  .single:nth-child(1) {
    background-image: url(https://images.unsplash.com/photo-1604689598793-b8bf1dc445a1?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2FwaXRhbCUyMG1hcmtldHxlbnwwfHwwfHx8MA%3D%3D);
  }
  
  .single:nth-child(2) {
    background-image: url(https://images.unsplash.com/photo-1613531784761-a1240fca16ac?w=1000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGNhcGl0YWwlMjBtYXJrZXR8ZW58MHx8MHx8fDA%3D);
  }
  
  .single:nth-child(3) {
    background-image: url(https://images.unsplash.com/photo-1556761175-5973dc0f32e7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fGNhcGl0YWwlMjBtYXJrZXR8ZW58MHx8MHx8fDA%3D);
  }
  
  .single:nth-child(4) {
    background-image: url(https://images.unsplash.com/photo-1497635151228-b6a5cb0c3f74?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzh8fGNhcGl0YWwlMjBtYXJrZXR8ZW58MHx8MHx8fDA%3D);
  }
  
  .single:nth-child(5) {
    background-image: url(https://images.unsplash.com/photo-1565012889418-b89ab51fbc41?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fGNhcGl0YWwlMjBtYXJrZXR8ZW58MHx8MHx8fDA%3D);
  }
  
  .single:nth-child(6) {
    background-image: url(https://images.unsplash.com/photo-1668610593040-b3a1c8bb2966?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzF8fGNhcGl0YWwlMjBtYXJrZXR8ZW58MHx8MHx8fDA%3D);
  }
  
  .content {
    transform: rotate(-90deg);
    transition: 500ms ease-in-out transform;
    text-align: center;
  }
  
  .content p {
    color: #fff;
    padding: 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    transition: 500ms ease-in-out height, 500ms ease-in-out padding;
  }
  
  .content button {
    display: none;
  }
  
  .content h2 {
    text-align: center;
    color: #f0f8ff;
    text-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    transform: scale(2.5);
    transition: 500ms ease-in-out transform;
  }
  
  .single:hover {
    width: 500px;
  }
  
  .single:hover::before {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .single:hover .content {
    transform: rotate(0);
  }
  
  .single:hover h2 {
    transform: scale(1);
  }
  
  .single:hover p {
    height: fit-content;
    margin-bottom: 10px;
  }
  
  .single:hover button {
    display: block;
    transform: rotate(0);
  }
  
  /* Responsive styles */
  @media screen and (max-width: 1200px) {
    .containerr {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
    }
  
    .single {
      width: 45%;
      height: 200px;
    }
  
    .single:hover {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .containerr {
      flex-direction: column;
      align-items: center;
    }
  
    .single {
      width: 90%;
      height: 200px;
    }
  
    .single:hover {
      width: 100%;
      height: 300px;
    }
  
    .content h2 {
      font-size: 1rem;
    }
  
    .content p {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .single {
      width: 100%;
      height: 150px;
    }
  
    .single:hover {
      height: 250px;
    }
  
    .content h2 {
      font-size: 0.8rem;
    }
  
    .content p {
      font-size: 0.7rem;
    }
  }
  