/* Add this to your existing CSS */

.img-slide {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  .img-slide .slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  
  .img-slide .slide.active {
    opacity: 1;
  }
  
  .img-slide .slide .info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 20px; /* Adjust padding as needed */
    background: rgba(23, 62, 8, 0.5); /* Add a background color for better readability */
  }
  
  .img-slide .slide .info h2 {
    /* margin-top: 10%; */
    font-size: 2rem;
    margin-left: 20%;
  }
  
  .img-slide .slide .info p {
    color: white;
    font-size: 1rem;
    width: 40%;
    /* padding: 10px; */
    border-radius: 4px;
    margin-left: 20%;
  }

  .img-slide .slide .info .btnn {
    margin-top: 10px;
    margin-left: 20%;
  }
  
  .img-slide .navigation {
    z-index: 2;
    position: absolute;
    display: flex;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .img-slide .navigation .btn {
    background: rgba(255, 255, 255, 0.5);
    width: 12px;
    height: 12px;
    margin: 12px;
    border-radius: 50%;
    cursor: pointer;
  }

  .img-slide .navigation .btn.active {
    background: #fff; /* Update the background color for the active state */
  }
  
  @media (max-width: 820px) {
    .img-slide {
      height: 475px;
    }
  
    .img-slide .slide .info {
      padding: 10px 25px;
    }
  
    .img-slide .slide .info h2 {
      font-size: 35px;
      margin-left: 0;
    }
  
    .img-slide .slide .info p {
      width: 100%;
      font-size: 15px;
      margin-left: 0;
    }
  
    .img-slide .navigation {
      bottom: 0px;
    }
  
    .img-slide .navigation .btn {
      width: 10px;
      height: 10px;
      margin: 8px;
    }
  }
  
  /* Add similar media queries for other screen sizes */
  