@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  /* box-sizing: border-box; */
}

body {
  background-color: #f2f5f7;
}